const hours = [
    {
        day: "Sun",
        open: "10:00am",
        close: "7:00pm"
    },
    {
        day: "Mon",
        open: "10:00am",
        close: "7:00pm"
    },
    {
        day: "Tue",
        open: "10:00am",
        close: "7:00pm"
    },
    {
        day: "Wed",
        open: "10:00am",
        close: "7:00pm"
    },
    {
        day: "Thu",
        open: "10:00am",
        close: "7:00pm"
    },
    {
        day: "Fri",
        open: "10:00am",
        close: "8:00pm"
    },
    {
        day: "Sat",
        open: "10:00am",
        close: "8:00pm"
    }
]

export default hours